import './App.scss';
import Header from './Header';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import React, { Suspense } from 'react';
import ReactGa from 'react-ga';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
const Wedding = React.lazy(() => import('./Wedding'));


const honeyFundLink = 'https://www.honeyfund.com/wedding/adamkate';


class App extends React.Component {
  constructor() {
    super();
    ReactGa.initialize('UA-4516355-10');
    ReactGa.pageview('landing');
    this.state = {};
    // if (window.location.origin.includes('http://localhost')) {
    //   this.handleLogin();
    // }
  }

  handleLogin() {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', '/authcheck');
    xhr.setRequestHeader(
        'Authorization', `Basic ${btoa(`${this.state.password}`)}`);
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) {
        return;
      }
      if (xhr.status === 200) {
        this.setState({ authenticated: true });
      } else {
        this.setState({ errorMessage: 'Incorrect password.' });
      }
    }
    this.setState({ errorMessage: 'Wait...' });
    xhr.send();
  }

  handleLoginFetch() {
    this.setState({ errorMessage: 'Wait...' });
    fetch('/authcheck', {
      headers: new Headers({
        'Authorization': `Basic ${btoa(`${this.state.password}`)}`
      }),
    }).then(rsp => {
      if (rsp.status === 200) {
        this.setState({ authenticated: true });
      } else {
        this.setState({ errorMessage: 'Incorrect password.' });
      }
    })
  }

  wrap(content) {
    return (
      <div className='App'>
        <Header authenticated={this.state.authenticated} />
        <div className='content'>
          {content}
        </div>
        <div className='Footer' />
      </div>
    );
  }

  renderUnsupported() {
    return (
      <div className='section center'>
        <p>Your browser can't render this content.</p>
        <p>Please use a modern browser, or email us directly for the content.</p>
      </div>
    );
  }

  renderLogin() {
    return (
      <div className='section loginform center'>
        <div>
          <p>Enter the password to continue</p>
        </div>
        <div className='inputWrapper'>
          <InputText 
              value={(this.state && this.state.password) || ''}
              type={window.innerWidth < 600 ? 'number' : ''}
              autoFocus='autofocus'
              onKeyPress={e => e.which === 13 && this.handleLogin(this)}
              onChange={e => this.setState({password: e.target.value})} />
          <Button
              label='Continue' icon='pi pi-chevron-right' iconPos='right'
              onClick={this.handleLogin.bind(this)} />
        </div>
        <div className='errorMessage'>
          <p>{this.state.errorMessage}</p>
        </div>
        <p className='honeyfund'>
          We're lucky to already have a home full of everything we need, and we ask that you not send gifts. Really. If you feel you absolutely must disregard this advice, please click through to our <a href={honeyFundLink}>registry</a>.
        </p>
      </div>
    );
  }

  renderWedding() {
    return (
      <Suspense fallback={<div />}>
        <Wedding />
      </Suspense>
    );
  }

  render() {
    let result;
    const isSupported = typeof Promise !== typeof undefined;
    if (!isSupported) {
      result = this.renderUnsupported();
    } else if (this.state.authenticated) {
      result = this.renderWedding();
    } else {
      result = this.renderLogin();
    }
    return this.wrap(result);
  }
}

export default App;
