import './Header.scss';
import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


const scrollState = {
  TOP: {
    scrollY: 0, // px
    'content-height': 200,
    'title-opacity': 1,
    'title-max-height': 70,
    'nav-margin-top': 24,
  },
  MID: {
    scrollY: 200, // px
    'content-height': 80,
    'title-opacity': 0,
    'title-max-height': 0,
    'nav-margin-top': 0,
  }
};


const getAttr = (scrollTop, key, suffix) => {
  const a = scrollState.TOP[key];
  const b = scrollState.MID[key];
  const min = scrollState.TOP.scrollY;
  const max = scrollState.MID.scrollY;
  if (scrollTop <= min) {
    return a + suffix;
  }
  if (scrollTop >= max) {
    return b + suffix;
  }

  const val = (((max - scrollTop) * a + (scrollTop - min) * b) / (max - min));
  return val + suffix;
};


const navItems = ({ extraClasses }) => {
  let className = 'nav-item ' + extraClasses;
  return (
    <div>
      <a href='#story'><div className={className}>Our Wedding</div></a>
      <a href='#keyinfo'><div className={className}>Key Info</div></a>
      <a href='#venue'><div className={className}>Venue</div></a>
      <a href='#lodging'><div className={className}>Lodging</div></a>
      <a href='#reg'><div className={className}>Registry</div></a>
      <a href='#people'><div className={className}>People</div></a>
    </div>
  );
};



class Header extends React.Component {
  constructor() {
    super();
    this.state = {scrollTop: window.scrollY};
    this.debounce = 0;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    const now = Date.now();
    if (Date.now() - this.debounce > 10 /* milliseconds */) {
      this.debounce = now;
      this.setState({scrollTop: window.scrollY});
    }
  }

  render() {
    return (
      <div className='Header'>
        <div className='header-content'
            style={{
              'height': getAttr(this.state.scrollTop, 'content-height', 'px'),
            }}>
          <div className='title'
              style={{
                'opacity': getAttr(this.state.scrollTop, 'title-opacity', ''),
                'maxHeight': getAttr(this.state.scrollTop, 'title-max-height', 'px'),
              }}>
            Kate & Adam
          </div>
          <div
              className={this.props.authenticated ? 'nav' : 'hidden'}
              style={{
                'marginTop': getAttr(this.state.scrollTop, 'nav-margin-top', 'px'),
              }}>
            <div className='desktop'>
              {navItems({})}
            </div>
            <div className='mobile'>
              <Button
                  className='nav-button p-button-raised p-button-rounded'
                  icon='pi pi-chevron-down'
                  onClick={() => this.setState({navVisible: true})} />
              <Dialog 
                  visible={this.state.navVisible}
                  onHide={() => this.setState({navVisible: false})}
                  modal={true}
                  dismissableMask={true}
                  showHeader={false}>
                <div onClick={() => this.setState({ navVisible: false })}>
                  {navItems({extraClasses: 'mobile'})}
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Header;
